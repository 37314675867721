var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.roomDialog),callback:function ($$v) {_vm.roomDialog=$$v},expression:"roomDialog"}},[_c('v-card',{attrs:{"elevation":"8"}},[_c('v-card-title',[_vm._v("Seleziona stanza")]),_c('v-card-text',[_c('v-autocomplete',{staticClass:"mx-4",attrs:{"loading":_vm.roomsLoading,"items":_vm.allrooms,"search-input":_vm.search,"item-text":"text","item-value":"ID","cache-items":"","flat":"","hide-no-data":"","hide-details":"","label":"Ricerca stanza"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.selectedRoom),callback:function ($$v) {_vm.selectedRoom=$$v},expression:"selectedRoom"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.selectedRoom===null},on:{"click":function($event){return _vm.addRoom()}}},[_vm._v("OK")]),_c('v-btn',{on:{"click":function($event){_vm.roomDialog=false}}},[_vm._v("Annulla")])],1)],1)],1),(_vm.user.SOURCE === 'local')?_c('v-btn',{on:{"click":function($event){_vm.selectedRoom=null; _vm.roomDialog=true}}},[_vm._v("Assegna stanza")]):_vm._e(),_c('v-data-table',{attrs:{"elevation":"10","dense":"","headers":_vm.headers,"items":_vm.rooms,"item-key":"ID","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna stanza ")])]},proxy:true},{key:"item.RM_NAME",fn:function({ item }){return [(item.LOCAL_NAME)?_c('span',[_vm._v(_vm._s(item.LOCAL_NAME))]):_c('span',[_vm._v(_vm._s(item.RM_NAME))])]}},{key:"item.DA",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.UserRoom.STARTDATE)))])]}},{key:"item.icons",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
              _vm.userRole === 'admin' ||
              _vm.userRole === 'operator' ||
              _vm.userRole === 'auditor'
            )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":'/rooms/' + item.ID}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Visualizza")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
              _vm.userRole === 'admin'
            )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.selectedRoom=item.ID; _vm.removeRoom();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-exit-run")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Rimuovi stanza")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }